import "../../../App.css";
import "./Specs.css";

import Images from "../../../assets/images";

export default function SpecTechnicalClientManager() {
  return (
    <div className="job-spec">
      <div className="job-spec-content">
        <img alt="zamna logo" src={Images.logos.zamna} />
        <br />
        <br />
        <h2>
          <strong>Senior Technical Client Engagement Manager</strong>
        </h2>
        <br />
        <p className="spec-paragraph">
          <strong>1 ABOUT</strong>
        </p>
        <p className="spec-paragraph">
          Zamna is an <strong>award-winning</strong> private equity-backed
          software company creating digital <strong>intelligence</strong> within
          the travel and aviation ecosystem by connecting and validating
          passenger data. The highly secure, <strong>GDPR</strong>-compliant,
          and&nbsp;
          <strong>patented</strong> technology validates, verifies and persists
          traveller data such as&nbsp;
          <strong>passports, visas, regulatory</strong> / health data to
          drastically reduce the time spent on physical document checks.
        </p>
        <p className="spec-paragraph">
          The digital intelligence is created via a customer-centric dynamic
          checklist running invisibly in the background of airline operations,
          checking passengers travel data against ever-changing regulations
          according to the flight destination.{" "}
          <strong>Prior to arriving at the airport</strong>, and{" "}
          <u>
            as part of the online check-in process, Zamna can instantly validate
            a passenger&#39;s &#34;ready to fly&#34; status
          </u>
          . This enables transit directly from the taxi to the aircraft with all
          document checks performed in advance, avoiding excess queues,
          streamlining airline and airport processes, and enabling the most
          cost-efficient operation.
        </p>
        <p className="spec-paragraph">
          Collaborating with airlines and government agencies since 2016, Zamna
          has completed more than 60 million passport verifications, and is the
          current holder for <strong>IATA&#39;s oneID innovation award</strong>.
          Other notable associations include: a long-standing partnership with{" "}
          <strong>British Airways</strong>&#39; owner International Airlines
          Group;&nbsp;
          <strong>Arab Air Carriers Organisation</strong> (AACO);&nbsp;
          <strong>ICAO</strong> Global Travel Recovery project; a collaboration
          between&nbsp;
          <strong>Emirates</strong> Airlines and the UAE&#39;s General
          Directorate of Residency and Foreigners (GDRFA) to improve airport
          security; solution deployments with <strong>WestJet</strong> in North
          America and Volaris in South America; and investment from{" "}
          <strong>LocalGlobe</strong> &mdash; one of the most prolific and
          successful seed investors in Europe &mdash; as well as and{" "}
          <strong>Oxford Capital</strong>.
        </p>
        <p className="spec-paragraph">
          Zamna software implementation is as frictionless as can be &mdash;
          with no new infrastructure needed for airports or airlines. Crucially,
          Zamna achieves better data <strong>validation and security</strong>
          &nbsp; by sharing <strong>no</strong> passenger personal data - in any
          form - with any 3rd parties. Our award-winning,{" "}
          <strong>privacy-by-design architecture</strong>&nbsp; enshrines the
          principle that &#34;the best way to keep data safe is not to have it
          at all&#34;.
        </p>
        <p className="spec-paragraph">
          Zamna received a $5 million seed round investment from Venture Capital
          firms including LocalGlobe, Oxford Capital, FOMCAP, CyLon and Seedcamp
          and are now looking for a vibrant, culturally aligned Technical client
          Engagement manager to look after our strategic clients, help enhance
          product strategy and act as the conduit between the commercial and
          technical teams within the business.
        </p>
        <p className="spec-paragraph">
          <strong>2 BACKGROUND TO THE ROLE</strong>
        </p>
        <p className="spec-paragraph">
          This is an incredible opportunity to join an innovative, successful
          and growing VC-backed software business at a very exciting time of
          revenue growth.
        </p>
        <p className="spec-paragraph">
          The Senior Technical Client Engagement Manager will{" "}
          <strong>report directly to Alex Gorelik, CTO</strong>, while working
          closely alongside the CEO, Irra Khi and VP Product, Raoul Cooper. The
          successful candidate will take responsibility for long-term client
          success through the&nbsp;
          <strong>adoption and expansion of our software use</strong>, as well
          as ensure our <strong>product strategy and positioning</strong> are
          communicated to both technical and non-technical audiences within our
          customer base.
        </p>
        <p className="spec-paragraph">
          This role will entice a candidate that is{" "}
          <strong>ambitious, hardworking, thirsty to learn</strong> and push
          themselves to be the best they can &mdash; an internal drive which is
          shared by each member of the Zamna team. No ambition is too big, no
          job is too small &mdash; the team all do whatever it takes to achieve
          excellence.
        </p>
        <p className="spec-paragraph">
          If you want to fuel some{" "}
          <strong>
            <u>
              of the most professionally satisfying, varied, and invigorating
              experiences of your career, this is it
            </u>
          </strong>
          . Zamna is invested in identifying skills and strengths within an
          individual, and then creating career paths with these in mind.
        </p>
        <p className="spec-paragraph">
          The role will define and implement the playbook for how Zamna engages
          with clients as we grow fast. Repeatable, reliable and results-driven.
          This role will ensure Zamna is seen in a professional light as well as
          being a highly responsive organisation. The role will help guide a
          client from a <strong>pilot implementation</strong> through to a{" "}
          <strong>long-term relationship</strong>&nbsp; driving high-value
          change to the client and expanding their use of our{" "}
          <strong>solution</strong>(s).
        </p>
        <p className="spec-paragraph">
          <strong>3 IDEAL CANDIDATE CHARACTERISTICS</strong>
        </p>
        <ul>
          <li>
            Excellent <strong>communication</strong> skills, both written and
            verbal &mdash; and an affinity to connect and build relationships
          </li>
          <li>
            Exceptional level of <strong>personal excellence</strong>{" "}
            demonstrated through every interaction and piece of work produced
          </li>
          <li>
            Excellent organisational and <strong>time management</strong> skills
            and an unwavering ability to work well under{" "}
            <strong>pressure</strong> without compromising on quality
          </li>
          <li>
            Hard working,{" "}
            <strong>
              logical thinker &mdash; with meticulous attention to detail
            </strong>
          </li>
          <li>
            <strong>Growth/Curious</strong> mindset and an inherent drive
            towards <strong>self-improvement</strong>
          </li>
          <li>
            Strong, <strong>emotionally stable personality</strong> who either
            effectively spots, prevents or successfully deals with challenges
            and <strong>conflicts</strong>
          </li>
          <li>
            Ability to formulate solutions or determine the best course of
            action, despite&nbsp;
            <strong>not always having full context or data</strong>
          </li>
          <li>
            Have a technical background with a strong ability to dive&nbsp;
            <strong>deep enough into technical</strong> concepts, development
            processes and issues &mdash; actual development experience is a plus
          </li>
          <li>
            Have an <strong>air travel background</strong> with a solid
            understanding of air travel systems and airport operational reality
          </li>
          <li>
            Have the <strong>desire to build a team</strong> and see success
            consistently repeated on an expanding portfolio
          </li>
          <li>
            Develop the playbook:{" "}
            <strong>
              tools and processes to repeat success and efficiency
            </strong>{" "}
            with a large and diverse client base
          </li>
          <li>
            Be a quick learner, swiftly grasping complex concepts and ideas, as
            well as deriving{" "}
            <strong>enjoyment from mind-bending amounts of new data</strong> and
            knowledge
          </li>
          <li>
            Be a careful listener, possessing excellent analytical skills and
            the ability to <strong>extract the essence</strong> of any given
            topic, from which responsible, pragmatic decisions are subsequently
            made
          </li>
          <li>
            Create processes and <strong>fully own them</strong>, while{" "}
            <strong>adapting</strong>&nbsp; quickly to ever-shifting priorities,
            context, circumstances, conditions and timelines
          </li>
          <li>
            Perpetual <strong>multitasker</strong> able to work on multiple
            projects/issues at the same time
          </li>
          <li>
            Work <strong>proactively</strong> with minimal supervision, having
            developed <strong>high autonomy</strong> in their work &mdash; a{" "}
            <strong>decision maker, not an order taker</strong>
          </li>
        </ul>
        <p className="spec-paragraph">
          <strong>4 PROJECT MANAGEMENT</strong>
        </p>
        <p className="spec-paragraph">
          <strong>
            Objective: Manage implementation projects and provide enterprise
            support for existing live products, with a view to scaling this
            process wherever possible as we continue our growth
          </strong>
        </p>
        <p className="spec-paragraph">
          <u>External</u>
        </p>
        <ul>
          <li>
            Lead <strong>integration scoping, estimations and planning</strong>{" "}
            with prospecting clients;
          </li>
          <li>
            Create, manage, drive, execute and fully{" "}
            <strong>own the client integration process</strong>
            from the product and technical side;
          </li>
          <li>
            Lead and participate in client <strong>technical meetings</strong>{" "}
            and calls; producing comprehensive{" "}
            <strong>
              meeting minutes, managing expectations and actions/backlog
            </strong>
            ;
          </li>
          <li>
            Chase stakeholders, remove blockers,{" "}
            <strong>bash through walls</strong>; Spot problems, identify issues
            and <strong>mitigate the risks</strong> arising from all of these;
          </li>
          <li>
            Keep <strong>clients fully updated</strong> on progress via regular
            stand-ups and&nbsp;
            <strong>high-quality materials</strong> (decks, Gantt charts,
            visuals, documentation etc.);
          </li>
          <li>
            Create, manage and maintain comprehensive{" "}
            <strong>project documentation</strong>;
          </li>
          <li>
            Ensure scope and delivery plans are <strong>aligned</strong> to
            commercial agreements (either formed or forming).
          </li>
        </ul>
        <p className="spec-paragraph">
          <u>Internal</u>
        </p>
        <ul>
          <li>
            Develop and guide the team using <strong>Agile</strong> principles
            and values, invoking specific methodology as needed
            (Scrum/Kanban/Squads & Tribes) for our unique full development
            lifecycle, where collaboration and collective ownership over
            processes are key enablers for team efficiency and success;
          </li>
          <li>
            Collaborate closely with the VP Product & CTO on{" "}
            <strong>
              prioritisation, roadmaps, milestones, backlog, traction and
              delivery
            </strong>
            ;
          </li>
          <li>
            Represent the <strong>voice of the client</strong> in planning and
            prioritising product work;
          </li>
          <li>
            Ensure that the product <strong>backlog is decomposed</strong> to
            the optimal level by the team so meaningful progress can be made
            most efficiently;
          </li>
          <li>
            Facilitate, refine, optimise and <strong>evolve</strong> all
            existing processes - such as <strong>troubleshooting</strong>{" "}
            ongoing issues - while creating new ones where they don&#39;t
            previously exist;
          </li>
          <li>
            Report and track burndown -{" "}
            <strong>improve runway projections</strong> with ever-improving
            prediction of backlog consumption;
          </li>
          <li>
            Spot problems, identify issues and mitigate the risks arising from
            all of these;
          </li>
          <li>
            <strong>Liaise with commercial</strong> team if customer issues
            could potentially impact overall relationship;
          </li>
          <li>
            Create, manage and maintain comprehensive project documentation.
          </li>
        </ul>
        <p className="spec-paragraph">
          <strong>5 PRODUCT MANAGEMENT</strong>
        </p>
        <ul>
          <li>
            Highlight and <strong>research</strong> various topics
            alongside&nbsp;
            <strong>product</strong> and customer development processes;
          </li>
          <li>
            Contribute towards product development{" "}
            <strong>process decision-making</strong>&nbsp; - consolidating and{" "}
            <strong>feeding back the voice of the technical team</strong>;
          </li>
          <li>
            Write <strong>scripts</strong> to collect <strong>data</strong> and
            perform comprehensive <strong>analysis</strong>, including{" "}
            <strong>hypothesis</strong>&nbsp; formulation and verification.
          </li>
        </ul>
        <p className="spec-paragraph">
          <strong>6 LOCATION + FURTHER INFORMATION</strong>
        </p>
        <p className="spec-paragraph">
          The role will be hybrid with a minimum of{" "}
          <strong>once-a-week office</strong>&nbsp; day in Zamna&#39;s London
          office. <strong>Some travel</strong> will be required in line with
          client needs and partnerships - <strong>up to 30% of the time</strong>
          , depending on project location and duration.
        </p>
        <p className="spec-paragraph">
          <strong>
            APPLY TO: <a href="raoul@zamna.com">raoul@zamna.com</a>
          </strong>
        </p>
        <p className="spec-paragraph">
          <strong></strong>
        </p>{" "}
      </div>
    </div>
  );
}
