import "./Header.css";

import clsx from "clsx";
import { useState } from "react";

import Images from "../../../assets/images";
import Config from "../../../config";
import Content, { ContentNS } from "../../../content/content";
import { useAppStore } from "../../../stores/app-store";
import BusinessWebsitePicker from "../../business-page/business-website-picker/BusinessWebsitePicker";
import LanguagePicker from "../../passenger-page/header/language-picker/LanguagePicker";

export default function Header() {
  const [languagePickerOpen, setLanguagePickerOpen] = useState(false);
  const [websitePickerOpen, setWebsitePickerOpen] = useState(false);
  const [burgerOpen, setBurgerOpen] = useState(false);

  const lang = useAppStore((state) => state.language);
  const t = Content.shared.t;

  return (
    <div className={clsx("header-root", burgerOpen && "fullscreen")}>
      <div className="content">
        <img alt="zamna logo" className="zamna-logo" src={Images.logos.zamna} />
        <div className={clsx("navigation", burgerOpen && "visible")}>
          <a
            href={Config.demoPageUrl(lang)}
            className="demo-page-button-header"
          >
            <img
              className="demo-page-button--icon"
              src={Images.icons.liveDemoButtonHeader}
              alt="arrow right"
            />
            <p>{`${t("demo-page-button")}`}</p>
          </a>
          <div className="separator" />

          <BusinessWebsitePicker
            open={websitePickerOpen}
            handleOpenStateChange={() => {
              setWebsitePickerOpen(true);
              setLanguagePickerOpen(false);
            }}
          />

          <LanguagePicker
            className="transparent"
            open={languagePickerOpen}
            closeOther={() => {
              setLanguagePickerOpen(true);
              setWebsitePickerOpen(false);
            }}
          />
        </div>
        <button
          className="burger-button"
          onClick={() => {
            setBurgerOpen((v) => !v);
          }}
        >
          <img
            alt="burger"
            src={burgerOpen ? Images.icons.closeBurger : Images.icons.burger}
          />
        </button>
      </div>
    </div>
  );
}
