import create from "zustand";

import Config from "../config";
import Content from "../content/content";
import ConsentCookie, { ConsentCookieValue } from "../cookies";

export enum Page {
  passenger = "pax",
  business = "business",
  privacyNotice = "privacy-notice",
  termsAndConditions = "terms-and-conditions",
  cookiesPolicy = "cookies-policy",
  specTechnicalClientManager = "technical-client-manager",
}

const startingPage = getStartingPage();

interface AppState {
  language: string;
  assetsLoaded: boolean;
  currentPage: Page;

  // null means the decision is not made yet and cookie banner is shown
  isConsentGiven: boolean | null;

  markAssetLoaded: () => void;
  changeLanguage: (lang: string) => void;
  giveConsent: (consent: boolean) => void;
}

export const useAppStore = create<AppState>((set, get) => ({
  language: initialLanguage(),
  isConsentGiven: isConsentGiven(),
  assetsLoaded: false,
  currentPage: startingPage,
  markAssetLoaded: () => {
    // document.dir = Content.shared.instance.dir()
    // setContentDirection(Content.shared.instance.dir())
    set(() => ({ assetsLoaded: true }));
  },

  giveConsent: (consent: boolean) =>
    set(() => {
      ConsentCookie.setConsentCookie(consent);

      return {
        isConsentGiven: consent,
      };
    }),

  changeLanguage: (lang: string) => {
    Content.shared.changeLanguage(lang);
    set(() => ({ language: lang }));
  },
}));

function initialLanguage(): string {
  const location = window.location;
  const searchParams = new URLSearchParams(location.search);
  const lang = searchParams.get("lang");

  const supportedLanguages =
    startingPage === Page.business
      ? Config.supportedLanguages.b2b
      : Config.supportedLanguages.b2c;

  if (!lang) {
    return "en";
  }

  if (!supportedLanguages.includes(lang)) {
    window.location.assign(`${location.origin}/?lang=en`);
  }

  return lang;
}

function isConsentGiven(): boolean | null {
  const cookieValue = ConsentCookie.getConsentCookie();

  if (cookieValue === null) {
    return null;
  }

  return ConsentCookie.getConsentCookie() === ConsentCookieValue.yes;
}

function getStartingPage() {
  const location = window.location;
  const path = location.pathname.replace(/\//g, "");

  if (path.includes(Page.termsAndConditions)) {
    return Page.termsAndConditions;
  }

  if (path.includes(Page.privacyNotice)) {
    return Page.privacyNotice;
  }

  if (path.includes(Page.cookiesPolicy)) {
    return Page.cookiesPolicy;
  }

  if (path.includes(Page.specTechnicalClientManager)) {
    return Page.specTechnicalClientManager;
  }

  if (path.includes(Page.passenger)) {
    return Page.passenger;
  }

  if (path !== "") {
    window.location.assign(`${location.origin}/${location.search}`);
  }

  return Page.business;
}
