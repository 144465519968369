import "./LanguagePicker.css";

import clsx from "clsx";
import { useEffect, useRef, useState } from "react";

import Images from "../../../../assets/images";
import Config from "../../../../config";
import Content from "../../../../content/content";
import { Page, useAppStore } from "../../../../stores/app-store";
import { capitalizeFirstLetter } from "../../../../utils";

interface Props {
  closeOther: () => void;
  open: boolean;
  className?: string;
}

function LanguagePicker(p: Props) {
  const [open, setOpen] = useState(false);
  const language = useAppStore((state) => state.language).toLowerCase();
  const currentPage = useAppStore((state) => state.currentPage);

  const supportedLanguages =
    currentPage === Page.business
      ? Config.supportedLanguages.b2b
      : Config.supportedLanguages.b2c;
  const t = Content.shared.t;

  const listRef = useRef<HTMLDivElement>(null);
  const pickerButtonRef = useRef<HTMLDivElement>(null);

  useEffect(outsideClickEffect, []);
  useEffect(() => {
    setOpen(p.open);
  }, [p.open]);

  return (
    <div className={clsx("language-picker", p.className && p.className)}>
      <div
        className="picker-button"
        ref={pickerButtonRef}
        onClick={onItemSelect}
        onTouchStart={onItemSelect}
      >
        <div className="left-content">
          <img
            className="globe-icon"
            alt="icon of a flag"
            src={Images.icons.globeBlue}
          />
          <div className="locale-name">{capitalizeFirstLetter(language)}</div>
        </div>
        <img
          className="chevron-icon"
          alt="icon of a chevron pointing down"
          src={Images.icons.chevronDown}
        />
      </div>

      {open && (
        <div className="language-list" ref={listRef}>
          {supportedLanguages.map((locale) => (
            <div
              className="language-list-item"
              key={locale}
              onClick={() => selectLanguage(locale)}
              onTouchEnd={() => {
                selectLanguage(locale);
              }}
            >
              <div className="language-list-item-title">
                {`${t(`ui.language.${locale}`)}`}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  function onItemSelect(e: any) {
    e.preventDefault();
    e.stopPropagation();
    p.closeOther();
    setOpen(true);
  }

  function selectLanguage(lang: string) {
    window.location.assign(
      `${window.location.origin}${window.location.pathname}?lang=${lang}`
    );
  }

  function outsideClickEffect() {
    function handleClick(e: Event) {
      if (listRef.current === null) {
        return;
      }

      if (e.target === null) {
        return;
      }

      if (!listRef.current.contains(e.target as HTMLElement)) {
        setOpen(false);
      }
    }

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }
}

export default LanguagePicker;
