class FlagImages {
  es = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/ES.svg";
  fr = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/FR.svg";
  en = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/GB.svg";
  pt = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/PT.svg";

  getFlagByLocale(l: string) {
    switch (l) {
      case "es":
        return this.es;
      case "fr":
        return this.fr;
      case "en":
        return this.en;
      case "pt":
        return this.pt;
    }
  }
}

class LogoImages {
  zamna = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/zamna-logo.svg";
  zamnaWhite =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/zamna-logo-white.svg";

  iag = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/iag-logo.svg";
  ba = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/ba-logo.svg";
  techcrunch =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/tc-logo.svg";
  travolution =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/tl-logo.svg";
  westjet = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/wj-logo.svg";
  volaris = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/volaris.svg";
  sky = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/sky-logo.svg";
  times = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/times-logo.svg";
  bbc = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/bbc-logo.svg";
  wired = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/wired-logo.svg";
  forbes =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/forbes-logo.svg";
  
    b2bBa = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/b2b-ba.svg";
  b2bCity = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/b2b-city.svg";
  b2bEmirates =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/b2b-emirates.svg";
  b2bForbes =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/b2b-forbes.svg";

  b2bIag = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/b2b-iag.svg";
  b2bSita = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/b2b-sita.svg";
  b2bAaco =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/Arab_Air_Carriers_Organization_Logo.svg";
  b2bIcao = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/b2b-icao.svg";
  b2bIco = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/b2b-ico.svg";

  b2bLinkedIn =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/b2b-linkedin.svg";

  b2bMedium =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/b2b-medium.svg";
  b2bPhocus =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/b2b-phocus.svg";
  b2bSky = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/b2b-sky.svg";
  b2bTimes =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/b2b-times.svg";
  b2bTravolution =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/b2b-travolution.svg";
  b2bWestJet =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/b2b-westjet.svg";
  b2bWired =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/b2b-wired.svg";

  b2bVolaris = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/b2b-volaris.svg";

  awardsLufthansa =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/b2b-lufthansa.svg";
  awardsIata =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/b2b-iata.svg";
  awardsEmirates =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/b2b-emirates.svg";
  awardsPhocus =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/b2b-phocus.svg";
  awardsAirfrance =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/airfrance.png";
  aviation = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/aviation.png";
  businessAwards =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/business-awards.png";
  eit = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/eit.png";
  hangar51 = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/hangar51.png";
  travelHospitality =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/travel-hospitality.png";
  ukbaa = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/ukbaa.png";

  cityam = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/city.svg";
}

class QuotesLogos {
  airArabia =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/quotes-logos/airarabia.png";
  ba =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/quotes-logos/ba.png";
  caribbean =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/quotes-logos/caribbean.png";
  ethiopian =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/quotes-logos/ethiopian.png";
  travelPort =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/quotes-logos/travelport.png";
  westjet =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/quotes-logos/westjet.png";
  sita =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/quotes-logos/sita.png";
  canaero = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/canaero.jpeg";
}
class IconImages {
  chevronDown =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/chevron-down.svg";
  chevronRightBlue =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/chevron-right-blue.svg";
  pax = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/site-pax.svg";
  airline =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/site-airline.svg";
  planeBlue =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/plane-blue.svg";
  planeWhite =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/plane-white.svg";
  globeBlue =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/globe-blue.svg";
  arrowRight =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/arrow-right.svg";
  burger = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/icons/burger-icon.svg";
  closeBurger = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/icons/close_burger_icon.svg"
  bannerPassport = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/icons/banner-icon-passport.svg"
  bannerPlane = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/icons/banner-icon-plane.svg"
  bannerGlobe = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/icons/banner-icon-globe.svg"
  bannerShield = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/icons/banner-icon-shield.svg"
  liveDemoButtonHeader = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/icons/live-demo-button-header-icon.svg"
}

class FaqImages {
  plus = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/plus.svg";
  minus = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/minus.svg";
}

class Images {
  static flags = new FlagImages();
  static logos = new LogoImages();
  static quotesLogos = new QuotesLogos();
  static faq = new FaqImages();
  static icons = new IconImages();

  static weAreGlobalDesktop = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/icons/we-are-global-desktop.png"
  static weAreGlobalMobile = "https://zamna-com.s3.eu-west-1.amazonaws.com/images/icons/we-are-global-mobile.png"
  static readyAtHome =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/ready-at-home.svg";
  static iconNeverSee =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/icon-never-see.svg";
  static globe =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/globe.svg";
  static flowMobile =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/flow-mobile.png";
  static flowDesktop =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/flow-desktop.png";
  static consent = (locale: string) =>
    `https://zamna-com.s3.eu-west-1.amazonaws.com/images/consent_${locale}.svg`;
  static safeStorage = (locale: string) =>
    `https://zamna-com.s3.eu-west-1.amazonaws.com/images/safe-storage_${locale}.svg`;
  static cookie =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/cookie.svg";
  static b2bMain =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/b2b-main.jpeg";

  static b2bNews1 =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/b2b-news-1.png";
  static b2bNews2 =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/b2b-news-2.png";
  static b2bNews3 =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/b2b-news-3.png";
  static b2bNews4 =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/b2b-news-4.png";

  static b2bIconSend =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/b2b-send-icon.svg";

  static b2bPlayOverlay =
    "https://zamna-com.s3.eu-west-1.amazonaws.com/images/play-frame.png";

  static whatWeDoVideo = (locale: string) =>
    `https://zamna-com.s3.eu-west-1.amazonaws.com/images/video/what-we-do_${locale}.mp4`;
}

export default Images;
